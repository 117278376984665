import React, { useState } from 'react'
import styled from 'styled-components';
import { lightThemeColors } from '../../assets/styles/colors';

const DocumentationPage = () => {

    return (
        <Container>

            <PageContent>
                <Header>
                    <HeaderTitle>
                        Documentation
                    </HeaderTitle>
                </Header>
                <Content>
                    <Line>
                        <Subtitle>API Endpoint</Subtitle>
                    </Line>
                    <Group>
                        <GroupTitle>
                            Endpoint
                        </GroupTitle>
                        <GroupText>URL: <span> https://api.emotii.ai/api/v1/connector/integration/translate</span></GroupText>
                        <GroupText>Method: <span>POST</span></GroupText>
                    </Group>
                    <Group>
                        <GroupTitle style={{ margin: 0 }}>
                            Authentication
                        </GroupTitle>
                        <GroupTextThin>To authenticate, you need to send a header X-Authorization with your API Key as its value.
                        </GroupTextThin>
                    </Group>
                    <Group>
                        <GroupTitle >
                            Headers
                        </GroupTitle>
                        <GroupText>Content-Type: <span>application/json</span></GroupText>
                        <GroupText>X-Authorization: <span>{`<YOUR_API_KEY>`}</span></GroupText>
                    </Group>
                    <Group>
                        <GroupTitle style={{ margin: 0 }}>
                            Request Body
                        </GroupTitle>
                        <GroupTextThin>The request body must be in JSON format and include the following fields:</GroupTextThin>
                        <GroupText style={{ marginTop: "20px" }}>message: <span>The text you want to translate.</span></GroupText>
                        <GroupText>languages: <span>An array of language codes to which you want the text to be translated.</span></GroupText>
                        <GroupText>mood: <span>The mood of the message as a string -- this is optional</span></GroupText>
                    </Group>
                    <Group>
                        <GroupText>Example Request Body:</GroupText>
                    </Group>
                    <CustomPre>
                        <code>
                            {`
    { `}
                            {`
         "`}<Red>languages</Red>{`" `} :  [<Purple>"Romanian", "French"</Purple>] ,
                            {`    
         "`}<Red>message</Red>{`" `}: {`"`}<Purple>How are you?</Purple>{`" `},
                            {`    
         "`}<Red>mood</Red>{`" `}: {`"`}<Purple>formal</Purple>{`" `} //optional
                            {`
    }
          `}
                        </code>
                    </CustomPre>

                    <Group>
                        <GroupTitle style={{ margin: 0 }}>
                            Response
                        </GroupTitle>
                        <GroupTextThin>The response will be an array of translated text strings corresponding to the requested languages.
                        </GroupTextThin>
                    </Group>
                    <Group>
                        <GroupText>Example Response:</GroupText>
                    </Group>
                    <CustomPre>
                        {`
    {`}{`                
        "`}<Red>response</Red>{`":`} {`[
          {  `}
                        <Purple> {`        
            "language": "Romanian",
            "response": "Cum sunteți?"
    `}</Purple>
                        {`      }, 
         `}
                        {`{  `}
                        <Purple> {`        
            "language": "French",
            "response": "Comment vas-tu?"
    `}</Purple>
                        {`      }
      ] `}



                        {`
 }
     
`}

                    </CustomPre>

                    <Group>
                        <GroupTitle>
                            Error Handling
                        </GroupTitle>

                        <GroupText>400 Bad Request: <span style={{ color: lightThemeColors.darkColor, fontWeight: 400 }}>Returned if the request body is not properly formatted or required fields are missing.</span></GroupText>
                        <GroupText>401 Unauthorized: <span style={{ color: lightThemeColors.darkColor, fontWeight: 400 }}>Returned if the X-API-KEY header is missing or contains an invalid API Key.                        </span></GroupText>
                        <GroupText>500 Internal Server Error: <span style={{ color: lightThemeColors.darkColor, fontWeight: 400 }}>Returned if there is an error on the server side.</span></GroupText>
                    </Group>
                    <Group>
                        <GroupText>Example</GroupText>
                    </Group>
                    <CustomPre>
                        <code >
                            {`
      curl --location 'https://api.emotii.ai/api/v1/connector/integration/translate' \\
        --header 'X-API-KEY: YOUR_API_KEY' \\
        --header 'Content-Type: application/json' \\
        --data '{
        "languages": ["Romanian", "Franch"],
        "message": "How are you?",
        "mood": "formal"
    }'

          `}
                        </code>
                    </CustomPre>

                </Content>

            </PageContent>
        </Container >
    )
}

export default DocumentationPage

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow:hidden;
  
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow:hidden;
  padding-bottom: 30px;
  padding: 40px 30px 0 40px;
  position: relative;
  height: 100%;
`;

const Header = styled.div`
display:flex;
align-items:center;
`
const HeaderTitle = styled.h1`
color:${lightThemeColors.darkColor};
font-size: 30px;
font-weight: 600;
line-height: 36px
text-align: left;
`
const Subtitle = styled.h2`
color:${lightThemeColors.darkColor};
font-size: 24px;
font-weight: 700;
line-height: 30px
text-align: left;
`

const Line = styled.div`
display:flex;
align-items:center;
width:100%;
`
const Content = styled.div`
display:flex;
flex-direction:column;
gap:30px;
height:100%;
overflow:auto;
padding-bottom:10px;
`

const Group = styled.div`
display:flex;
flex-direction:column;
gap:5px;
margin-left:10px;
`
const GroupTitle = styled.h3`
color:${lightThemeColors.darkColor};
font-size: 16px;
font-weight: 700;
line-height: 20px
text-align: left;
margin-bottom:15px;
`
const GroupText = styled.p`
color:${lightThemeColors.darkColor};
font-size: 14px;
font-weight: 600;
line-height: 18px
text-align: left;
span{
color:${lightThemeColors.primaryColor};
}
`
const GroupTextThin = styled.p`
color:rgba(56, 56, 58, 0.7);
font-size: 13px;
font-weight: 400;
line-height: 16px
text-align: left;

`
const CustomPre = styled.pre`
font-family:"Menlo";
background:rgba(34, 39, 46, 1);
color:white;
font-size: 12px;
font-weight: 400;
line-height: 14px;
text-align: left;
border-radius:4px;
max-width:450px;

`
const Red = styled.span`
color:rgba(252,115,76);
font-family:Menlo;
`
const Purple = styled.span`
color:rgba(72,165,240);
font-family:Menlo;
`